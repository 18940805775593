.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

ol li{
  list-style-type:decimal;
}

.enlarge:hover .enlarge-icon{
  visibility: visible;
  z-index: 100;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.leftWrapper{
  max-width: 75%;
}
.title {
  font-weight: 900;
  font-size: 13vw;
  margin: 0;
  position: relative;
  letter-spacing: -.1em;
  color: IndianRed;
  text-transform: uppercase;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.popup-content{
  width: 70% !important;
}
.popup-overlay{
  background:rgba(0,0,0,0.8) !important;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width:768px){
.active{
  background-color:#000000;
 }
}

@media (max-width:768px){
  .popup-content{
    width: 90% !important;
  }
}

.notfound{
  text-shadow: 4px 4px 1px #300000,
       4px 6px 1px #400000,
       4px 8px 1px #500000,
       4px 10px 1px #600000,
       4px 12px 1px #700000,
       4px 14px 1px #800000,
       4px 16px 1px #900000,
       4px 18px 1px #A00000,
       4px 20px 1px #B00000,
       4px 22px 1px #C00000,
       4px 24px 1px #D00000,
       4px 26px 1px #E00000;
}

.drop {
  width: .6em; height: .6em;
  border-radius: 0 100% 100% 100%;
  background-color: #c00000;
  position: absolute;
  top: 72%;
  animation: drop 3s infinite both;}

  .drop:nth-child(1) {
    left: 30%;
  }

  .drop:nth-child(2) {
    left: 49%;
    animation-delay: -.7s;
  }

  .drop:nth-child(3) {
    left: 65%;
    animation-delay: -1.5s;
  }

  .drop:nth-child(4) {
    left: 62%;
    animation-delay: -.8s;
  }

  .drop:nth-child(5) {
    left: 75.5%;
    animation-delay: -1.3s;
  }

@keyframes drop {
  0% {
    transform: translateY(0) scaleX(.85) rotate(45deg);
    animation-timing-function: ease-out;
  }
  60% {
    transform: translateY(80%) scaleX(.85) rotate(45deg);
    animation-timing-function: ease-in;
  }
  80%, 100% {
    transform: translateY(110vh) scaleX(.85) rotate(45deg);
  }
}

#searchName{
  scroll-margin-top: 5em;
}

div.desc p{
  margin-top: 10px;
  margin-bottom: 10px !important;
}

.chq-atc--button svg{
  display: inline-block;
}
